import './App.css';

import React, { useState } from 'react';

import { BrandGithub, BrandGitlab, BrandDiscord, BrandTelegram, At, Package, BrandReddit } from 'tabler-icons-react';
import { Tooltip, Switch } from 'antd';

export default function App() {
    const [isTouch, setIsTouch] = useState((('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)));
    const [animationsEnabled, setAnimationsEnabled] = useState(localStorage && !localStorage.getItem("disable-animations"));
    const [windowWidth, setWindowWidth] = useState(window.outerWidth);
    const [windowHeight, setWindowHeight] = useState(window.outerHeight);
    const [landingPageTransformXAxis, setLandingPageTransformXAxis] = useState(0);
    const [landingPageTransformYAxis, setLandingPageTransformYAxis] = useState(10);
    const [landingPageTransformDegrees, setLandingPageTransformDegrees] = useState(0);

    function checkIsTouch() {
        if (isTouch !== (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0))) setIsTouch(!isTouch);
    }

    window.addEventListener('resize', () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
        checkIsTouch();
    });

    let animatedFirstTime = true;
    if (localStorage && !localStorage.getItem("animated-first-time")) {
        animatedFirstTime = false;
        localStorage.setItem("animated-first-time", true);
    }

    if (animationsEnabled) {
        function animationBodyBackgroundParticles() {
            const body = document.body;
            let increase = true;
            let firstPositionPixels = 20;
            let secondPositionPixels = 20;
            setInterval(() => {
                firstPositionPixels += increase ? 1 : -1;
                secondPositionPixels += increase ? -1 : 1;

                if (firstPositionPixels >= 40) increase = false;
                else if (firstPositionPixels <= 20) increase = true;

                body.style.backgroundPosition = `${secondPositionPixels}px 0,${firstPositionPixels}px 20px`;
            }, 50);
        }

        animationBodyBackgroundParticles();
    }

    function movedMouse(event) {
        if (!animationsEnabled) return;

        /* Metà larghezza ed altezza dello schermo, ossia il punto centrale, essendo il testo centrato */
        let halfWidth = windowWidth / 2;
        let halfHeight = windowHeight / 2;

        /* Distanza euclidea */
        let equationDegrees = Math.sqrt(Math.pow((event.clientX - halfWidth), 2) + Math.pow((event.clientY - halfHeight), 2)) * 50 / (Math.sqrt(Math.pow(halfWidth, 2) + Math.pow(halfHeight, 2)));
        const distanceWidth = Math.abs(event.clientX - halfWidth);
        const distanceHeight = Math.abs(event.clientY - halfHeight);
        const equationXAxis = (distanceHeight * 9 / halfHeight) + 1;
        const equationYAxis = (distanceWidth * 9 / halfWidth) + 1;

        let left = true;
        if (event.clientX > halfWidth) left = false;
        let top = true;
        if (event.clientY > halfHeight) top = false;

        setLandingPageTransformXAxis(top ? -equationXAxis : equationXAxis)
        setLandingPageTransformYAxis(left ? equationYAxis : -equationYAxis);
        setLandingPageTransformDegrees(equationDegrees);
    }

    function animationsSwitchChange() {
        if (localStorage) {
            if (animationsEnabled) {
                setAnimationsEnabled(false);
                setLandingPageTransformDegrees(0);
                setLandingPageTransformXAxis(0);
                setLandingPageTransformYAxis(0);
                localStorage.setItem("disable-animations", true);
            } else {
                setAnimationsEnabled(true);
                localStorage.removeItem("disable-animations");
            }
        }
    }

    checkIsTouch();
    
    return (
        <div id="app" onMouseMove={movedMouse} onTouchEnd={(event) => { event.preventDefault() }}>
            <div id="background-elements">
                <div id="top-background-element"></div>
                <div id="bottom-background-element"></div>
                <div id="rounded1" className={animatedFirstTime ? "paused-animation" : "running-animation"}></div>
                <div id="rounded2" className={animatedFirstTime ? "paused-animation" : "running-animation"}></div>
                <div id="rounded3" className={animatedFirstTime ? "paused-animation" : "running-animation"}></div>
                <div id="rounded4" className={animatedFirstTime ? "paused-animation" : "running-animation"}></div>
            </div>
            <div id="landing-page" className={animatedFirstTime ? "paused-animation" : "running-animation"} style={{"transform": `rotate3D(${landingPageTransformXAxis}, ${landingPageTransformYAxis}, 0, ${landingPageTransformDegrees}deg)`}}>
                <h1>Ciao, sono Daniele De Martino.</h1>
                <span id="secondary">
                    Front-end | Back-end | DevOps
                </span>
                <div id="social-icons">
                    <Tooltip title="DanielVip3" arrowPointAtCenter={true}>
                        <a className="hvr-grow" href="https://github.com/DanielVip3" target="_blank" rel="noopener noreferrer">
                            <BrandGithub size={48}></BrandGithub>
                        </a>
                    </Tooltip>
                    <Tooltip title="DanielVip3" arrowPointAtCenter={true}>
                        <a className="hvr-grow" href="https://gitlab.com/DanielVip3" target="_blank" rel="noopener noreferrer">
                            <BrandGitlab size={48}></BrandGitlab>
                        </a>
                    </Tooltip>
                    <Tooltip title="@DanielVip3" arrowPointAtCenter={true}>
                        <a className="hvr-grow" href="https://t.me/DanielVip3" target="_blank" rel="noopener noreferrer">
                            <BrandTelegram size={48}></BrandTelegram>
                        </a>
                    </Tooltip>
                    <Tooltip title="DanielVip3#6167" arrowPointAtCenter={true}>
                        <a className="hvr-grow" href="https://discord.bio/p/danielvip3" target="_blank" rel="noopener noreferrer">
                            <BrandDiscord size={48}></BrandDiscord>
                        </a>
                    </Tooltip>
                    <Tooltip title="DanielVip3" arrowPointAtCenter={true}>
                        <a className="hvr-grow" href="https://www.reddit.com/user/DanielVip3" target="_blank" rel="noopener noreferrer">
                            <BrandReddit size={48}></BrandReddit>
                        </a>
                    </Tooltip>
                    <Tooltip title="danieledemartino.72004@gmail.com" arrowPointAtCenter={true}>
                        <a className="hvr-grow" href="mailto:danieledemartino.72004@gmail.com" target="_blank" rel="noopener noreferrer">
                            <At size={48}></At>
                        </a>
                    </Tooltip>
                    <Tooltip title="danielvip3" arrowPointAtCenter={true}>
                        <a className="hvr-grow" href="https://www.npmjs.com/~danielvip3" target="_blank" rel="noopener noreferrer">
                            <Package size={48}></Package>
                        </a>
                    </Tooltip>
                </div>
            </div>
            { !isTouch ?
                <div id="disable-animations-zone">
                    <span>Animazioni</span>
                    <Switch id="disable-animations-switch" checked={animationsEnabled} onChange={animationsSwitchChange} />
                </div>
                : null
            }
        </div>
    );
}